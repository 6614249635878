<template>
	<div class="warning-audit">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="企业名称">
					<el-input v-model="formSearch.companyName" placeholder="请输入企业名称"></el-input>
				</el-form-item>
				<el-form-item label="风险判定">
					<el-select v-model="formSearch.judge" clearable>
						<el-option label="正确" value="1"></el-option>
						<el-option label="错误" value="2"></el-option>
						<el-option label="未审核" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="风险标签">
					<el-select v-model="formSearch.tagId" clearable>
						<el-option v-for="(item,index) in riskTagList" :key="index" :label="item.tagName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审核人">
					<el-input v-model="formSearch.auditUserName" placeholder="请输入企业名称"></el-input>
				</el-form-item>
				<el-form-item label="审核时间">
					<el-date-picker v-model="formSearch.auditTime" value-format="yyyy-MM-dd" @change="getAuditTime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerFindTime"></el-date-picker>
				</el-form-item>
				<el-form-item label="发现时间">
					<el-date-picker v-model="formSearch.findTime" value-format="yyyy-MM-dd" @change="getFindTime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerAuditTime"></el-date-picker>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="searchNameFn">检索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<h4 class="title-h4" style="float: left;">推荐风险点总数：<span>{{totalItems}}</span> 条</h4>
				<el-table border v-loading="loadingFlag" :data="tableData" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="companyName" label="企业名称" width="250" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span @click="companyNameLink(scope.row)" class="color-m" style="cursor: pointer;">{{scope.row.companyName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="platfomProduct" label="平台产品" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="tagName" label="风险标签" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="abstracts" label="风险内容">
						<template slot-scope="scope">
							<el-tooltip placement="top" effect="dark">
								<div slot="content">
									<span v-html="scope.row.abstracts">{{scope.row.abstracts}}</span>
								</div>
								<span class="ellipsis" v-html="scope.row.abstracts">{{scope.row.abstracts}}</span>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="title" label="舆情标题" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span @click="handleModalYQ(scope.row)" class="color-m" style="cursor: pointer;">{{scope.row.title}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="url" label="风险来源" width="80">
						<template slot-scope="scope">
							<a class="color-m" :href="scope.row.url" target="_blank">
								<i class="iconfont icon-lianjie1"></i>
							</a>
						</template>
					</el-table-column>
					<el-table-column prop="insertTime" label="发现时间" width="150" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span v-if="scope.row.insertTime==null">-</span>
							<span v-else>{{scope.row.insertTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="风险判定" width="80" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="auditTime" label="风险审核时间" width="150" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span v-if="scope.row.auditTime==null">-</span>
							<span v-else>{{scope.row.auditTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="auditUserName" label="审核人" width="80" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="" label="操作" width="60">
						<template slot-scope="scope">
							<i class="iconfont icon-bianji color-m" title="编辑" @click="handleEditTJXG(scope.$index, scope.row)" style="cursor: pointer;margin: 0 5px;"></i>
							<i class="iconfont icon-ic_detail color-m" title="详情" @click="handleEditBJTJXQ(scope.$index, scope.row)" style="cursor: pointer;margin: 0 5px;"></i>
						</template>
					</el-table-column>
					<el-table-column prop="reason" label="备注" width="120" :show-overflow-tooltip="true"></el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
		<!-- 推荐风险点修改   弹框 -->
		<el-dialog title="推荐风险点审核" :visible.sync="dialogVisibleTJXG" width="30%">
			<el-form :inline="true" class="demo-form-inline" :model="ruleFormTJ" ref="ruleFormTJ">
				<el-form-item>
					<el-radio v-model="ruleFormTJ.yesOrNo" label="1" @change='reasonClearable'>正确</el-radio>
					<el-radio v-model="ruleFormTJ.yesOrNo" label="-1">错误</el-radio>
				</el-form-item>
				<el-form-item>
					<el-select v-model="ruleFormTJ.reason" :disabled="ruleFormTJ.yesOrNo == 1" clearable>
						<el-option v-for="(item,index) in ruleFormTJ.reasonList" :key="index" :label="item.reason" :value="item.reason"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="saveRiskPointTJ('ruleFormTJ')">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 推荐风险点详情   弹框 -->
		<el-dialog title="推荐风险点详情" :visible.sync="outerVisibleTJXQ" width="80%">
			<div class="table-box">
				<el-table border :data="tableRiskPointTJXQ" :row-style="{height:'40px'}" @row-click="handleRowRiskPointTJXQ">
					<el-table-column type="index" :index="typeIndexTJXQ" label="序号" width="50"></el-table-column>
					<el-table-column prop="title" label="名称" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="type" label="类型" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.type == 4">新闻</span>
							<span v-if="scope.row.type == 5">博客</span>
							<span v-if="scope.row.type == 6">论坛</span>
							<span v-if="scope.row.type == 8">微信</span>
							<span v-if="scope.row.type == 9">微博</span>
							<span v-if="scope.row.type == 10">APP</span>
						</template>
					</el-table-column>
					<el-table-column prop="pubtime" label="时间" width="200"></el-table-column>
					<el-table-column prop="sourceUrl" label="来源" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<a class="color-m" :href="scope.row.sourceUrl" target="_blank">{{scope.row.sourceUrl}}</a>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination @current-change="handleCurrentChangeTJXQ" :total="totalRiskPointTJXQ" :current-page="pageNumRiskPointTJXQ" :page-size="pageSizeRiskPointTJXQ" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
			<!-- 内层  单条详情 -->
			<el-dialog width="60%" title="" :visible.sync="innerVisibleTJXQ" append-to-body class="modal-box">
				<div class="modal-body-box" v-loading="loadingFlag2">
					<div class="ellipsis modal-title" :title="titleTJXQ" v-html="titleTJXQ">{{titleTJXQ}}</div>
					<div class="modal-content">
						<span v-if="sourceTypeTJXQ == 4">信息源：<b>新闻</b></span>
						<span v-if="sourceTypeTJXQ == 9">信息源：<b>微博</b></span>
						<span v-if="sourceTypeTJXQ == 10">信息源：<b>APP</b></span>
						<span v-if="sourceTypeTJXQ == 5">信息源：<b>网站</b></span>
						<span v-if="sourceTypeTJXQ == 8">信息源：<b>微信</b></span>
						<span v-if="sourceTypeTJXQ == 6">信息源：<b>论坛</b></span>
						<span v-if="pubtimeTJXQ != ''">发布时间：<b>{{pubtimeTJXQ}}</b></span>
					</div>
					<div class="modal-article">
						<div style="width: 100%;height: 600px;">
							<el-scrollbar style="height: 100%;width:100%">
								<span v-html="contentTJXQ">{{contentTJXQ}}</span>
							</el-scrollbar>
						</div>
					</div>
				</div>
			</el-dialog>
		</el-dialog>
		<!-- 舆情 详情 -->
		<el-dialog title="" :visible.sync="dialogVisibleYQ" width="70%" class="modal-box">
			<div class="modal-body-box" v-loading="loadingFlag1">
				<div class="ellipsis modal-title" v-html="dialogVisibleInfoYQ.title">{{dialogVisibleInfoYQ.title}}</div>
				<div class="modal-content">
					<span v-if="dialogVisibleInfoYQ.source_type == 4">信息源：<b>新闻</b></span>
					<span v-if="dialogVisibleInfoYQ.source_type == 9">信息源：<b>微博</b></span>
					<span v-if="dialogVisibleInfoYQ.source_type == 10">信息源：<b>APP</b></span>
					<span v-if="dialogVisibleInfoYQ.source_type == 5">信息源：<b>网站</b></span>
					<span v-if="dialogVisibleInfoYQ.source_type == 8">信息源：<b>微信</b></span>
					<span v-if="dialogVisibleInfoYQ.source_type == 6">信息源：<b>论坛</b></span>
					<span v-if="dialogVisibleInfoYQ.source_type=='9' || dialogVisibleInfoYQ.source_type == '8'">
						<span v-if="dialogVisibleInfoYQ.site != ''">作者：<b>{{dialogVisibleInfoYQ.site}}</b></span>
					</span>
					<span v-if="dialogVisibleInfoYQ.source_type!='9' && dialogVisibleInfoYQ.source_type != '8'">
						<span v-if="dialogVisibleInfoYQ.site != ''">站点：<b>{{dialogVisibleInfoYQ.site}}</b></span>
					</span>
					<span v-if="dialogVisibleInfoYQ.pubtime != ''">发布时间：<b>{{dialogVisibleInfoYQ.pubtime}}</b></span>
					<span v-if="dialogVisibleInfoYQ.source_url != ''">
						<a :href="dialogVisibleInfoYQ.source_url" target="_blank" title="源链接">
							<i class="iconfont icon-lianjie1"></i>
						</a>
					</span>
				</div>
				<div class="modal-content">
					<span v-if="dialogVisibleInfoYQ.companyName != ''">企业名称：<b>{{dialogVisibleInfoYQ.companyName}}</b></span>
					<span v-if="dialogVisibleInfoYQ.productName != ''">产品名称：<b>{{dialogVisibleInfoYQ.productName}}</b></span>
					<span v-if="dialogVisibleInfoYQ.platformName != ''">平台名称：<b>{{dialogVisibleInfoYQ.platformName}}</b></span>
					<span v-if="dialogVisibleInfoYQ.abstractKw != ''">关键词：<b>{{dialogVisibleInfoYQ.abstractKw}}</b></span>
					<span v-if="dialogVisibleInfoYQ.riskLabelName != ''">风险标签：<b>{{dialogVisibleInfoYQ.riskLabelName}}</b></span>
				</div>
				<div class="modal-article">
					<div style="width: 100%;height: 600px;">
						<el-scrollbar style="height: 100%;width:100%">
							<span v-html="dialogVisibleInfoYQ.content">{{dialogVisibleInfoYQ.content}}</span>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "warning-audit",
	data() {
		return {
			loadingFlag: false,
			loadingFlag1: false,
			loadingFlag2: false,
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			riskTagList: [], //风险标签
			//发现时间
			pickerFindTime: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			//审核时间
			pickerAuditTime: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			formSearch: {
				companyName: '',
				judge: '',
				tagId: '',
				findTime: null,
				auditUserName: '',
				auditTime: null,
			},
			dataTableEmpty: false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
			dialogVisibleTJXG: false, //是否打开编辑弹框  推荐风险点修改
			ruleFormTJ: {
				reasonList: [],
				yesOrNo: "",
				reason: '',
				riskTagsId: ""
			},
			outerVisibleTJXQ: false, //是否打开编辑弹框  推荐风险点详情  外层
			tableRiskPointTJXQ: [], //列表  推荐风险点详情  外层
			tableRiskPointTJXQtagId:"",
			tableRiskPointTJXQcompanyId: "",
			totalRiskPointTJXQ: 0,
			pageNumRiskPointTJXQ: 1,
			pageSizeRiskPointTJXQ: 10,
			innerVisibleTJXQ: false, //是否打开编辑弹框  推荐风险点详情  内层
			titleTJXQ: "",
			sourceTypeTJXQ: "",
			pubtimeTJXQ: "",
			contentTJXQ: "",
			//舆情详情
			dialogVisibleYQ: false, //是否打开详情弹框
			dialogVisibleInfoYQ: {
				title: "",
				source_type: "",
				site: "",
				pubtime: "",
				source_url: '',
				content: "",
				companyName: "",
				productName: "",
				platformName: "",
				riskLabelName: "",
				abstractKw: "",
			}
		}
	},
	created() {
		this.getRiskTagList(); //加载风险标签
		this.getPageList(); //加载列表
		this.getRiskpointReasonList(); //加载判断弹框   错误原因
	},
	mounted() {},
	computed: {},
	methods: {
		//加载风险标签
		getRiskTagList() {
			this.$provider.get('/wg-ifrRisk/riskAudit/getRiskTagList', {}).then(res => {
				this.riskTagList = res.data;
			})
		},

		//获取发现时间  值
		getFindTime(val) {
			this.formSearch.findTime = val; //这个sTime是在data中声明的，也就是v-model绑定的值
		},
		//获取审核时间  值
		getAuditTime(val) {
			this.formSearch.auditTime = val; //这个sTime是在data中声明的，也就是v-model绑定的值
		},

		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true; //loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/riskAudit/getRecommendRiskLabelList', {
				params: {
					companyName: this.formSearch.companyName.replace(/^\s+|\s+$/g, ""),
					judge: this.formSearch.judge != "" ? this.formSearch.judge : "",
					tagId: this.formSearch.tagId != "" ? this.formSearch.tagId : "",
					findStartTime: this.formSearch.findTime != null ? this.formSearch.findTime[0] : "",
					findEndTime: this.formSearch.findTime != null ? this.formSearch.findTime[1] : "",
					auditUserName: this.formSearch.auditUserName,
					auditStartTime: this.formSearch.auditTime != null ? this.formSearch.auditTime[0] : "",
					auditEndTime: this.formSearch.auditTime != null ? this.formSearch.auditTime[0] : "",
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res => {
				this.loadingFlag = false; //loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//点击机构   跳转详情页
		companyNameLink(item) {
			let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
			user_xjfj['defaultActive'] = 1019;
			sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
			const icardUrl = this.$router.resolve({
				path: '/institutionalPortraitInfo',
				query: {
					name: item.companyName,
					id: item.companyId
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面
		},

		//查看舆情详情
		handleModalYQ(result) {
			this.dialogVisibleInfoYQ.title = "";
			this.dialogVisibleInfoYQ.source_type = "";
			this.dialogVisibleInfoYQ.pubtime = "";
			this.dialogVisibleInfoYQ.source_url = "";
			this.dialogVisibleInfoYQ.content = "";
			this.dialogVisibleInfoYQ.companyName = "";
			this.dialogVisibleInfoYQ.productName = "";
			this.dialogVisibleInfoYQ.platformName = "";
			this.dialogVisibleInfoYQ.riskLabelName = "";
			this.dialogVisibleInfoYQ.abstractKw = "";
			this.dialogVisibleYQ = true; //打开弹框
			this.getDetailInfoYQ(result.id);
		},
		getDetailInfoYQ(result) {
			this.loadingFlag1 = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/riskPoint/getDetailInfo', {
				params: {
					riskLabelId: result
				}
			}).then(res => {
				this.loadingFlag1 = false; //loading 消失
				this.dialogVisibleInfoYQ.title = res.data.title;
				this.dialogVisibleInfoYQ.source_type = res.data.source_type;
				this.dialogVisibleInfoYQ.site = res.data.site;
				this.dialogVisibleInfoYQ.pubtime = res.data.pubtime;
				this.dialogVisibleInfoYQ.source_url = res.data.source_url;
				this.dialogVisibleInfoYQ.content = res.data.content;
				this.dialogVisibleInfoYQ.companyName = res.data.companyName;
				this.dialogVisibleInfoYQ.productName = res.data.productName;
				this.dialogVisibleInfoYQ.platformName = res.data.platformName;
				this.dialogVisibleInfoYQ.riskLabelName = res.data.riskLabelName;
				this.dialogVisibleInfoYQ.abstractKw = res.data.abstractKw;
			})
		},

		//推荐风险点修改  弹框
		handleEditTJXG(index, result) {
			this.dialogVisibleTJXG = true; //打开弹框  
			if (result.status == "正确") {
				this.ruleFormTJ.yesOrNo = "1";
				this.ruleFormTJ.reason = "";
			} else if (result.status == "错误") {
				this.ruleFormTJ.yesOrNo = "-1";
				this.ruleFormTJ.reason = result.reason;
			} else {
				this.ruleFormTJ.yesOrNo = "";
				this.ruleFormTJ.reason = "";
			}
			this.ruleFormTJ.riskTagsId = result.id;
		},
		//加载判断弹框   错误原因
		getRiskpointReasonList() {
			this.$provider.get('/wg-ifrRisk/riskPoint/getRiskpointReasonList', {}).then(res => {
				this.ruleFormTJ.reasonList = res.data;
			})
		},
		//正确    错误原因   清空
		reasonClearable() {
			this.ruleFormTJ.reason = "";
		},
		//推荐风险点修改   确定保存
		saveRiskPointTJ(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.ruleFormTJ.yesOrNo != "") {
						this.$provider.post('/wg-ifrRisk/riskPoint/riskAudit', {
							"id": Number(this.ruleFormTJ.riskTagsId),
							"status": this.ruleFormTJ.yesOrNo,
							"reason": this.ruleFormTJ.reason,
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									message: "推荐风险点判断成功",
									type: 'success'
								});
								this.dialogVisibleTJXG = false; //关闭弹框
								this.getPageList(); //加载列表 推荐风险点
							} else {
								this.$message({
									message: res.msg,
									type: 'error'
								});
								return false;
							}
						})
					} else {
						alert("请先选择正确与错误");
						return false;
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		//推荐风险点详情  弹框
		handleEditBJTJXQ(index, result) {
			this.outerVisibleTJXQ = true; //打开弹框  外层
			this.pageNumRiskPointTJXQ = 1;
			this.tableRiskPointTJXQtagId = result.tagId;
			this.tableRiskPointTJXQcompanyId = result.companyId;
			this.tableDataRiskPointTJXQ(); //推荐风险点详情  弹框   列表
		},
		//推荐风险点详情  弹框   列表
		tableDataRiskPointTJXQ() {
			this.$provider.get('/wg-ifrRisk/riskPoint/getRiskDetailList', {
				params: {
					tagId: this.tableRiskPointTJXQtagId,
					companyId: this.tableRiskPointTJXQcompanyId,
					current: this.pageNumRiskPointTJXQ,
					size: this.pageSizeRiskPointTJXQ,
				}
			}).then(res => {
				this.tableRiskPointTJXQ = res.data.records;
				this.totalRiskPointTJXQ = res.data.total;
			})
		},
		//点击 推荐风险点详情列表  属于第几页
		handleCurrentChangeTJXQ(val) {
			this.pageNumRiskPointTJXQ = val;
			this.tableDataRiskPointTJXQ(); //加载  推荐风险点详情  弹框   列表
		},
		typeIndexTJXQ(index) {
			return (this.pageNumRiskPointTJXQ - 1) * this.pageSizeRiskPointTJXQ + index + 1;
		},
		//点击 推荐风险点详情列表的某一行    加载对应详情
		handleRowRiskPointTJXQ(row, event, column) {
			this.outerVisibleTJXQ = true; //打开弹框  外层
			this.innerVisibleTJXQ = true; //打开弹框  内层
			this.getDetailInfoTJXQ(row.id);
		},
		getDetailInfoTJXQ(result) {
			this.loadingFlag2 = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/riskPoint/getDetailInfo', {
				params: {
					riskDetailId: result
				}
			}).then(res => {
				this.loadingFlag2 = false; //loading 消失
				this.titleTJXQ = res.data.title;
				this.sourceTypeTJXQ = res.data.source_type;
				this.pubtimeTJXQ = res.data.pubtime;
				this.contentTJXQ = res.data.content;
			})
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
